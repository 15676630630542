<template>
  <v-layout column>
    <v-layout justify-center>
      <slot name="content"/>
    </v-layout>
    <v-layout justify-center>
      <slot name="message"/>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'empty-content',
};
</script>
