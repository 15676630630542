<template>
  <v-layout row wrap  class="py-1">
    <div class="mt-3 mr-2 subheading">Ordenar por:</div>
    <v-flex xs5 md3 d-flex>
      <v-select
        v-model="orderBy"
        :items="items"
        item-text="name"
        item-value="sortBy"
        :menu-props="{ 'offset-y': true }"
        @change="selectOrder"
        outline
        single-line
      ></v-select>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'sort-mtrs',
  props: ['sortBy'],
  mounted() {
    [this.orderBy] = this.items.filter(el => el.sortBy === this.sortBy);
  },
  data() {
    return {
      orderBy: {},
      items: [
        {
          name: 'Data de emissão',
          sortBy: 'created_at',
        },
        {
          name: 'Data de recebimento',
          sortBy: 'received_at',
        },
        {
          name: 'Número do MTR',
          sortBy: 'number',
        },
      ],
    };
  },
  methods: {
    selectOrder(order) {
      this.$emit('selectOrder', order);
    },
  },
};
</script>
