<template>
  <div class="text-xs-center">
    <v-pagination
      v-model="page"
      :length="totalPages"
      circle
    />
  </div>
</template>

<script>
export default {
  name: 'pagination-list',
  props: ['totalPages', 'totalMtrs'],
  data() {
    return {
      page: 1,
    };
  },
};
</script>
