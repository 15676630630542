var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    _vm._l(_vm.cards, function (card, index) {
      return _c(
        "v-flex",
        {
          key: index,
          attrs: { xs3: "" },
          on: {
            click: function ($event) {
              return _vm.toggleActive(card)
            },
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "custom-card",
              style: {
                border: `1px solid ${card.borderColor}`,
                "background-color": _vm.cardIsActive(card)
                  ? card.backgroundColor
                  : "white",
              },
              attrs: { flat: "" },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "custom-title pt-1 pb-0 grey--text" },
                [_vm._v(_vm._s(card.title))]
              ),
              _c(
                "v-card-text",
                { staticClass: "amount py-0 grey--text text--darken-1" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "custom-text",
                      attrs: { "align-center": "" },
                    },
                    [_vm._v(_vm._s(card.amount || 0))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }