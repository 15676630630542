var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticClass: "py-1", attrs: { row: "", wrap: "" } },
    [
      _c("div", { staticClass: "mt-3 mr-2 subheading" }, [
        _vm._v("Ordenar por:"),
      ]),
      _c(
        "v-flex",
        { attrs: { xs5: "", md3: "", "d-flex": "" } },
        [
          _c("v-select", {
            attrs: {
              items: _vm.items,
              "item-text": "name",
              "item-value": "sortBy",
              "menu-props": { "offset-y": true },
              outline: "",
              "single-line": "",
            },
            on: { change: _vm.selectOrder },
            model: {
              value: _vm.orderBy,
              callback: function ($$v) {
                _vm.orderBy = $$v
              },
              expression: "orderBy",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }