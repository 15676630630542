<template>
  <v-layout>
    <v-flex
      v-for="(card, index) in cards"
      :key="index"
      xs3
      @click="toggleActive(card)"
    >
      <v-card
        flat
        class="custom-card"
        :style="{
          'border': `1px solid ${card.borderColor}`,
          'background-color': cardIsActive(card) ? card.backgroundColor : 'white'
        }"
      >
        <v-card-title class="custom-title pt-1 pb-0 grey--text">{{ card.title }}</v-card-title>
        <v-card-text class="amount py-0 grey--text text--darken-1">
          <v-layout align-center class="custom-text">{{ card.amount || 0 }}</v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'filter-cards',
  props: ['cards', 'activeStatus'],
  data() {
    return {
    };
  },
  methods: {
    toggleActive(card) {
      this.$emit('changeActive', card);
    },
    cardIsActive(card) {
      return _.isEqual(card.status, this.activeStatus);
    },
  },
};
</script>

<style>
.custom-card {
  width: 160px;
  height: 75px;
  border-radius: 5px;
  cursor: pointer;
}
.custom-title {
  font-size: 14px;
}
.amount {
  font-size: 38px;
}

.custom-text {
  height: 45px;
}
</style>
